/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const React = require("react")
const { FirebaseProvider } = require("./src/context/FirebaseProvider")
const { AppProvider } = require("./src/context")

exports.wrapRootElement = ({ element }) => {
  return (
    <AppProvider>
      <FirebaseProvider>{element}</FirebaseProvider>
    </AppProvider>
  )
}

/* exports.onClientEntry = () => {
  if (process.env.NODE_ENV !== "production") {
    const whyDidYouRender = require("@welldone-software/why-did-you-render")
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    })
  }
} */
